import React from 'react'

function PhoneBy() {
    return (
      <div id="phone_by" className="">
        <section className="phone-by bg-light">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="phone-available">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="d-dsk">
                        Phone : Available Monday to Saturday, 9:30 am - 7:30 pm
                      </p>
                      <p className="d-mbl">
                        Phone : Available Monday to Saturday<br/> 9:30 am - 7:30 pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="clearfix"></div>
      </div>
    );
}

export default PhoneBy
