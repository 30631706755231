import React from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { socialLogin } from '../../store/actions/authActions';
import config from "../../config/app.js";

// const CLIENT_ID = '640854248047-l6nccs7je4du0op5t8fj99pdcr6noosv.apps.googleusercontent.com'
const CLIENT_ID = '245677219267-k36eaankonf4qj3apit605ntldpb80ar.apps.googleusercontent.com'

function GoogleAuth() {
    const dispatch = useDispatch();

    const onSuccessLogin = (res) => {
      const userObj = res.profileObj;
      const userDetails = {
        first_name: userObj?.name,
        last_name: "",
        telephone: "",
        country_id: "",
        email: userObj?.email,
        dob: "",
        password: "",
        user_type: "guest",
        social_login: "yes",
        is_seller: "0",
        city: "",
        postcode: "",
        company: "",
        confirm: "",
        fax: "",
        address_1: "",
        address_2: "",
      };

      dispatch(socialLogin(userDetails, "google")).then((data) => {
        if (!data) {
          // setSubmitting(false);
        }
      })
      .finally(() => {
        // setSubmitting(false);
      });;
    }

    const onFailureLogin = (res) => {
        console.log(res);
    }

    return (
        <div>
            <GoogleLogin 
                clientId={CLIENT_ID}
                render={renderProps => (
                  <a onClick={renderProps.onClick} disabled={renderProps.disabled} href="#0"><img src={config.skinURL + "/assets/images/icons/google.png"} alt="" /></a>
                  )}
                buttonText=''
                onSuccess={onSuccessLogin}
                onFailure={onFailureLogin}
                cookiePolicy={'single_host_origin'}
            />
        </div>
    )
}

export default GoogleAuth
