import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
// import SEO from "../components/Footer/SEO";
import PhoneBy from "../components/PhoneBy/PhoneBy";
// Importing the useSearchParams from react-router-dom
import { useSearchParams , useLocation } from "react-router-dom";
import React, { useState } from "react";

// function MainLayout({ children }) {
function MainLayout({ seoDetails, children }) {
  /**
   * BOC
   * Task#137377-Checkout Process Development for the App
   * author Maanik Arya
   * date 05-Apr-2023
   * use_of_code:- Removing the footer when we open the webview on Mobile App. 
   */
  const [searchParams] = useSearchParams();
  /**
   * BOC
   * Task#137377-Checkout Process Development for the App
   * author Mohammad Meraj
   * date 26-May-2023
   * use_of_code:- Update the condition for display the footer. If the app is open then we don't display the footer.
   */
  // const [isApp] = useState(searchParams?.get("app") || 0);
  const [isApp] = useState(searchParams?.get("app") || (localStorage.getItem('isApp') ? true : false));
  /**
   * BOC
   * author Maanik Arya
   * date 07-Apr-2023
   * use_of_code:- As per the client request they don't want to display the Footer in the Category page. 
   * There for we have calculated the pathname and if the pathname contains catalog then we are not display the Footer.
   */
  const { pathname } = useLocation();
  let isdisplayFooter = pathname.includes('catalog') ? false : true ; 
  /**
   * EOC
   */
  
  
  return (
    <>
      {children}
      {/**  Condition for display the Footer, If mobile app then we don't dispaly the footer. If catalog page opnes then we also don't display the footer. */}
      { (isdisplayFooter && !isApp) ? 
      <div className="has-bg-white">
        {/* Contact Section */}
        <Contact />

        {/* Phone By Section */}
        <PhoneBy />

        {/* Footer Section */}
        {/* <Footer /> */}
        
        <Footer seoDetails={seoDetails} />
        
        {/* SEO Content Section */}
        {/* <SEO seoDetails={seoDetails} /> */}
      </div>
     : ""}
    </>
  );
}

export default MainLayout;
