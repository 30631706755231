import axios from "axios";
import { requestConfig } from "../config/request";
import {
  API_RESPONSE_TIME_LOGGING_ENABLED,
  TOKEN_KEY,
  USER_KEY,
} from "./Constant";
import config from "../config/app";
import { isMobile } from "react-device-detect";

const { version } = config;

if (API_RESPONSE_TIME_LOGGING_ENABLED) {
  axios.interceptors.request.use((x) => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    x.meta = x.meta || {};
    x.meta.requestStartedAt = new Date().getTime();

    // Add mandatory parameters for each request
    x.params = {
      ...x.params,
      version: version,
      SID: localStorage.getItem(TOKEN_KEY),
      __store: "default",
    };

    return x;
  });

  axios.interceptors.response.use((x) => {
    // here we are updating the SID on each response.
    let SID = "";

    try {
      SID = JSON.parse(x.data || "")?.SID || "";
    } catch (e) {
      // log error
    }

    if (SID) {
      localStorage.setItem(TOKEN_KEY, SID);
    }

    console.log(
      `Execution time for: ${x.config.url} - ${
        new Date().getTime() - x.config.meta.requestStartedAt
      } ms`
    );
    return x;
  });
}

// Method to get custom error message and code
export const getCustomError = (err) => {
  let error = {
    message: "An unknown error occured",
  };

  if (
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.message
  ) {
    error.code = err.response.data.error;
    error.message = err.response.data.message;
  } else if (!err.response && err.message) {
    error.message = err.message;
  }

  return error;
};

// method to get data from local storage
export const getFromLocalStorage = async (key) => {
  try {
    const serializedState = await localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    if (typeof serializedState === "string") {
      return serializedState;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

// method to save data to local storage
export const saveToLocalStorage = async (key, value) => {
  try {
    const serializedState = value;
    await localStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignoring write error as of now
  }
};

// Method to delete the data from local storage
export const clearFromLocalStorage = async (key) => {
  try {
    await localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
};

// Method to get the request configuration from local storage
async function getRequestConfig(apiConfig) {
  let config = { ...requestConfig };
  if (apiConfig) {
    config = { ...requestConfig, ...apiConfig };
  }
  return config;
}

// GET Request API
export const get = async (url, params, apiConfig) => {
  const config = await getRequestConfig(apiConfig);
  config.params = params;
  const request = axios.get(url, config);
  return request;
};

// POST Request API
export const post = async (url, data, apiConfig) => {
  const config = await getRequestConfig(apiConfig);

  let postData = {};
  if (
    apiConfig &&
    apiConfig.headers &&
    apiConfig.headers["Content-Type"] &&
    apiConfig.headers["Content-Type"] !==
      "application/x-www-form-urlencoded; charset=UTF-8"
  ) {
    postData = data;
    axios.defaults.headers.post["Content-Type"] =
      apiConfig.headers["Content-Type"];
  } else {
    postData = data;
    axios.defaults.headers.post["Content-Type"] = "application/json";
  }

  // append mandatory post data for each request
  let user = localStorage.getItem(USER_KEY);
  if (user) {
    user = JSON.parse(user);
  }

  postData = {
    session_data: localStorage.getItem(TOKEN_KEY),
    id_currency: "INR",
    iso_code: "1",
    email: user ? user.email : "",
    platform: "web",
    device_is_mobile: isMobile,
    ...postData,
  };

  // converting post json data to formdata
  var bodyFormData = new FormData();
  for (var key in postData) {
    bodyFormData.append(key, postData[key]);
  }

  const request = axios.post(url, bodyFormData, config);
  return request;
};

// PUT Request API
export const put = async (url, data) => {
  const config = await getRequestConfig();
  config.headers["Content-Type"] = "application/json";
  const request = axios.put(url, JSON.stringify(data), config);
  return request;
};

// PATCH Request API
export const patch = async (url, data) => {
  const config = await getRequestConfig();
  config.headers["Content-Type"] = "application/json";
  const request = axios.patch(url, JSON.stringify(data), config);
  return request;
};

// DELETE Request API
export const deleteResource = async (url) => {
  const config = await getRequestConfig();
  const request = axios.delete(url, config);
  return request;
};
