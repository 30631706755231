import React from "react";

function ProductPrice({
  price,
  discount_price,
  discount_perc,
  show_discount_price,
  show_discount_perc,
  perc_text = "",
  show_price = true,
  page = "product",
  className = "",
  layout = "1",
  show_brackets = false,
}) {
  if (!show_price) return null;

  if (show_discount_price && discount_price) {
    return (
      <>
        <p className={`price-text ${className}`}>
          { parseInt(price.replace(/[₹,]/g, ''), 10)  >  parseInt(discount_price.replace(/[₹,]/g, ''), 10) && (
          <span className={`disc-mrp ${layout === "3" ? " d-block" : ""}`}>
            <del> {price}</del>
          </span>
          )}
          {layout === "1" || layout === "2" ? <>&nbsp;</> : null}
          <span
            className={`text-black price disc-price ${
              layout === "3" ? " d-block" : ""
            }`}
          >
            {" "}
            {discount_price}
          </span>
          {layout === "1" ? <>&nbsp;</> : null}
          {show_discount_perc && discount_perc ? (
            <>
              {discount_perc?.toString()?.includes("%") || !isNaN(discount_perc) ? (
                <span
                  className={`text-error ${layout !== "1" ? " d-block" : ""}`}
                >
                  {show_brackets && "("}
                  {discount_perc}
                  {perc_text}
                  {show_brackets && ")"}
                </span>
              ) : (
                <span
                  className={`text-badge ${layout !== "1" ? " d-block" : ""}`}
                >
                  {discount_perc}
                  {perc_text}
                </span>
              )}
            </>
          ) : null}
        </p>
      </>
    );
  } else {
    return (
      <>
        <p className={`price-text ${className}`}>
          <span className="text-black price"> {discount_price}</span>
        </p>
      </>
    );
  }
}

export default ProductPrice;
