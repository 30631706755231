const config = {
  isProd: process.env.NODE_ENV === "production",
  production: {
    api_endpoint: process.env.REACT_APP_PRODUCTION_API_ENDPOINT || "https://www.darveys.com/index.php/mobileappbuilder/apicall",
  },
  development: {
    api_endpoint: process.env.REACT_APP_DEVELOPMENT_API_ENDPOINT || "https://www.darveys.com/index.php/mobileappbuilder/apicall",
  },
  version: '1.11',
  skinURL: "https://skin.darveys.com",
  syncProduction: {
    api_endpoint: ["https://us.darveys.com/index.php/mobileappbuilder/apicall"]
  },
};

export default config;
