import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/app.js";

function PaymentMode() {
  return (
    <div className="payment-mode">
      <div className="foot-heading text-center-mobile">
        <h4>All payment modes accepted :</h4>
      </div>
      <ul className="modes mx-auto-mobile">
        <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/visa.png"}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/master-card.png"}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={
                config.skinURL + "/assets/images/american-express.png"
              }
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/net-banking.png"}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/emi.png"}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={
                config.skinURL + "/assets/images/cash-on-delivery.png"
              }
              alt=""
            />
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default PaymentMode;
