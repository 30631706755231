import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { QueryClient, QueryClientProvider } from "react-query";
import { DEFAULT_API_STALE_TIME } from "./utils/Constant";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import SnackbarProvider from "react-simple-snackbar";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, //automatically requests fresh data in the background if user leaves the app and returns to stale data.
      refetchOnmount: false, // if true, refetch on mount if the data is stale.
      refetchOnReconnect: false, // if true, refetch on reconnect if the data is stale.
      retry: false, // if true, failed queries will retry infinitely.
      staleTime: DEFAULT_API_STALE_TIME, // the time in milliseconds after data is considered stale. Defaults to 0.
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
