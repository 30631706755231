import config from "../config/app";
import * as API from "../utils/ApiHelper";

const { isProd } = config;

const API_ENDPOINT = isProd
  ? config.production.api_endpoint
  : config.development.api_endpoint;
  const SYNC_API_ENDPOINT = config.syncProduction.api_endpoint;  //COde to send the Signup request on the US website when we signup on the India site.

// User Login API
export const appLogin = (data) => {
  const url = `${API_ENDPOINT}/appLogin`;

  // preparing post data to send
  data = {
    password: "",
    ...data,
  };

  return API.post(url, data);
};

// User Register API
export const appRegisterUser = (data) => {
  const url = `${API_ENDPOINT}/appRegisterUser`;

  // preparing post data to send
  let apiData = {
    signup: JSON.stringify(data),
  };

  return API.post(url, apiData);
};

// Forgot Password API
export const appForgotPassword = (email) => {
  const url = `${API_ENDPOINT}/appForgotPassword`;

  // preparing post data to send
  let data = {
    email: email,
  };

  return API.post(url, data);
};

// User Login API
export const appSocialLogin = (data) => {
  const url = `${API_ENDPOINT}/appSocialLogin`;

  return API.post(url, data);
};

// Send OTP for Login
export const appSendLoginOtp = (data) => {
  const url = `${API_ENDPOINT}/appSendLoginOtp`;

  return API.post(url, data);
};

// Verify OTP for Login
export const appVerifyOTPAndLogin = (data) => {
  const url = `${API_ENDPOINT}/appVerifyOTPAndLogin`;
  return API.post(url, data);
};

export const appLogout = () => {
  const url = `${API_ENDPOINT}/appLogout`;
  return API.post(url);
};

export const syncRegisterUserData = (data) => {

  SYNC_API_ENDPOINT.map((url_endpoint) => {
    const url = `${url_endpoint}/appRegisterUser`;
    // preparing post data to send
    let apiData = {
      signup: JSON.stringify(data),
    };
    API.post(url, apiData);
  });  
};